<template>
  <tiep-nhan-truc-tuyen />
</template>
<script>
import TiepNhanTrucTuyen from '@/modules/tiep-nhan-ho-so/components/pages/tiep-nhan-truc-tuyen/TiepNhanTrucTuyen.vue'

export default {
  components: {
    TiepNhanTrucTuyen,
  },
}
</script>
