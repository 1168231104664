<template>
  <div class="tab-common">
    <input
      id="tab1"
      v-model="componentName"
      class="tab-input"
      checked="checked"
      type="radio"
      name="tab-common"
      value="trucTiep"
      @change="getDataHoSo"
    >
    <input
      id="tab2"
      v-model="componentName"
      class="tab-input"
      type="radio"
      name="tab-common"
      value="trucTiepBoSung"
      @change="getDataHoSo"
    >
    <nav>
      <ul class="tab-ul">
        <li
          v-if="checkQuyenHoSoTrucTuyen"
          class="tab1"
        >
          <label
            class="tab-label"
            for="tab1"
          >TRỰC TUYẾN</label>
        </li>
        <li
          v-if="checkQuyenHoSoTrucTuyenBoSung"
          class="tab2"
        >
          <label
            class="tab-label"
            for="tab2"
          >TRỰC TUYẾN BỔ SUNG</label>
        </li>
      </ul>
    </nav>
    <section class="tab-section">
      <div class="tab">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="d-sm-flex justify-content-start mb-1">
              <button
                class="compact ui button text-height"
                @click="refreshCommon"
              >
                <refresh-cw-icon
                  size="12"
                  class="custom-class line-top"
                  :stroke-width="3"
                />
                Tải lại
              </button>
              <button
                v-if="checkQuyenTiepNhan"
                class="compact ui btn-primary button"
                :class="selectedNumber < 1 ? 'disabled' : ''"
                @click="acceptHoSo"
              >
                <corner-down-right-icon
                  size="12"
                  class="custom-class"
                  :stroke-width="3"
                />
                Tiếp nhận
              </button>
            </div>
            <div class="d-flex align-items-center">
              <filter-icon
                size="20"
                class="cursor-pointer"
                :stroke-width="2"
                @click="showFormFilter"
              />
            </div>
          </div>
          <advanced-search
            v-show="formFilterShow"
            ref="formFilter"
            class="mb-2"
            @advanced-search="getDataHoSo"
          />
          <good-table
            ref="vgTable"
            :columns="getColumns"
            :rows="rows"
            :total="total"
            :is-loading="isLoading"
            :class="checkAllQuyenHoSoTrucTuyen ? '' : 'fixed-column'"
            @column-filter="columnFilter"
            @selected-item="selectedItem"
            @page-change="pageChange"
          >

            <template
              slot="custom-filter"
              slot-scope="props"
            >
              <treeselect
                v-if="props.props.column.field === 'tenThuTucHanhChinh'"
                id="linhVuc"
                ref="tenThuTucHanhChinh"
                v-model="payload.dataTTHCId"
                v-format-v-select
                :default-options="tthcCbx"
                placeholder="Chọn thủ tục hành chính"
                :limit="0"
                multiple
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                loading-text="Đang tìm kiếm"
                :normalizer="normalizer"
                :match-keys="['label', 'label2']"
                class="vtreeselect-chooser vtreeselect-chooser-tt"
                :load-options="onQueryChange"
                :async="true"
                :clear-on-select="true"
                :before-clear-all="clearTreeSelect"
                @select="$refs.tenThuTucHanhChinh.resetSearchQuery(); nextTickTreeSelect()"
                @deselect="nextTickTreeSelect()"
              />
            </template>
            <template
              slot="custom-row"
              slot-scope="props"
            >
              <span v-if="props.props.column.field == 'code'">
                <a
                  href="javascript:;"
                  @click="detailHoSo(props.props.row)"
                >{{ `${props.props.row.code}` }}</a>
              </span>
              <span v-else-if="props.props.column.field == 'trangThai'">
                <div>{{ props.props.row.tenTrangThai }}</div>
              </span>
              <div v-else-if="props.props.column.field === 'chuHoSo_HoVaTen'">
                <div
                  v-if="props.props.row.chuHoSo_HoVaTen"
                >
                  <user-icon size="14" /> {{ props.props.row.chuHoSo_HoVaTen }}
                </div>
                <div
                  v-if="props.props.row.chuHoSo_DiaChiDayDu"
                  class="text-top word-break"
                >
                  <map-pin-icon size="14" /> {{ props.props.row.chuHoSo_DiaChiDayDu }}
                </div>
              </div>
            </template>
            <template
              slot="actions"
              slot-scope="props"
            >
              <edit-2-icon
                v-if="checkQuyenYeuCauBoSung"
                v-b-tooltip.hover.v-secondary
                title="Y/C bổ sung"
                size="16"
                class="custom-class mr-1 cursor-pointer"
                @click="implementHoSo(props.props.row)"
              />
              <check-circle-icon
                v-if="(props.props.row.trangThai == 0 || props.props.row.trangThai == 3) && checkQuyenTiepNhan"
                v-b-tooltip.hover.v-secondary
                title="Tiếp nhận"
                size="16"
                class="custom-class mr-1 cursor-pointer"
                @click="detailHoSo(props.props.row)"
              />
              <x-circle-icon
                v-if="checkQuyenTuChoi"
                v-b-tooltip.hover.v-secondary
                title="Từ chối"
                size="16"
                class="custom-class mr-1 cursor-pointer"
                @click="rejectHoSo(props.props.row)"
              />
            </template>
          </good-table>
        </div>
      </div>
    </section>
    <common-modal
      ref="commomnModal"
      :title="title"
      :size="size"
      :content="contentModal"
      :title-ok="titleOk"
      @handle-ok="handleOk"
    >
      <component
        :is="componentNameModal"
        :data-form="dataForm"
        :class="componentNameModal === 'TiepNhanNhieuHoSo' ? 'scroll-class' : ''"
        @delete-ho-so="deleteHoso"
      />
    </common-modal>
  </div>
</template>

<script>
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BFormTextarea,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/named
import { ROUTE_NAME } from '@/modules/tiep-nhan-ho-so/constants/constants'
import { mapGetters } from 'vuex'
import {
  FilterIcon,
  Edit2Icon,
  CheckCircleIcon,
  UserIcon,
  MapPinIcon,
  CornerDownRightIcon,
  RefreshCwIcon,
  XCircleIcon,
} from 'vue-feather-icons'
import commonModal from '@/modules/tiep-nhan-ho-so/components/modal/CommonModal.vue'
import addCssSemantic from '@/mixins/mixins'
import GoodTable from '@/components/GoodTable.vue'
import LyDoTuChoHoSo from '@/modules/tiep-nhan-ho-so/components/form/LyDoTuChoHoSo.vue'
import YeuCauBoSung from '@/modules/tiep-nhan-ho-so/components/form/YeuCauBoSung.vue'
import { removeDiacritical } from '@/utils/index'
import TiepNhanNhieuHoSo from '@/modules/tiep-nhan-ho-so/components/form/TiepNhanNhieuHoSo.vue'
import _debounce from 'lodash/debounce'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _cloneDeep from 'lodash/cloneDeep'
import AdvancedSearch from '@/components/AdvancedSearch.vue'
import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'DanhSachHoSo',
  components: {
    AdvancedSearch,
    FilterIcon,
    Edit2Icon,
    CheckCircleIcon,
    XCircleIcon,
    BFormGroup,
    BFormTextarea,
    CornerDownRightIcon,
    RefreshCwIcon,
    UserIcon,
    MapPinIcon,
    commonModal,
    BCard,
    GoodTable,
    LyDoTuChoHoSo,
    YeuCauBoSung,
    TiepNhanNhieuHoSo,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      formFilterShow: false,
      totalPages: 0,
      totalRows: 0,
      isShow: false,
      total: 0,
      isLoading: true,
      title: '',
      size: '',
      componentName: 'trucTiep',
      contentModal: '',
      componentNameModal: '',
      dataForm: null,
      selectedItems: null,
      selectedNumber: 0,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Mã hồ sơ',
          field: 'code',
          width: '220px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          thClass: 'text-center',
          width: 'auto',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          thClass: 'text-center',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'trangThai',
          thClass: 'text-center',
          tdClass: 'text-left',
          width: '150px',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
          width: '120px',
        },
      ],
      rows: [],
      tthcCbx: [],
      lvCbx: [],
      hoSoId: null,
      titleOk: null,
      payload: {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: null,
        chuHoSo: null,
        maHoSo: null,
        diaChiChuHoSo: null,
        chuHoSo_DiaChiDayDu: null,
        thoiGianTiepNhanTu: null,
        thoiGianTiepNhanDen: null,
        thoiGianHenTraTu: null,
        thoiGianHenTraDen: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: null,
        pageNumber: 1,
        pageSize: 10,
      },
      permissionName: null,
      permissionType: [
        {
          name: 'HSTNTRUC_TUYEN',
          type: 'trucTiep',
        },
        {
          name: 'HSTNTRUC_TUYEN_BO_SUNG',
          type: 'trucTiepBoSung',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenHoSoTrucTuyen() {
      return hasPermission([PERMISSION_NAME.HSTNTRUC_TUYEN.DANH_SACH])
    },
    checkQuyenHoSoTrucTuyenBoSung() {
      return hasPermission([PERMISSION_NAME.HSTNTRUC_TUYEN.BO_SUNG_DANH_SACH])
    },
    checkQuyenTiepNhan() {
      return hasPermission([PERMISSION_NAME[this.permissionName].TIEP_NHAN])
    },
    checkQuyenYeuCauBoSung() {
      return hasPermission([PERMISSION_NAME[this.permissionName].YEU_CAU_BO_SUNG])
    },
    checkQuyenTuChoi() {
      return hasPermission([PERMISSION_NAME[this.permissionName].TU_CHOI])
    },
    checkAllQuyenHoSoTrucTuyen() {
      return [this.checkQuyenTiepNhan, this.checkQuyenYeuCauBoSung, this.checkQuyenTuChoi].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyenHoSoTrucTuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    if (this.$route.query.tuNgay) {
      this.formFilterShow = true
    }
    this.payload.navCode = this.$route.meta.code
    const nameComponent = this.$route.query.name
    if (nameComponent) {
      // eslint-disable-next-line no-nested-ternary
      this.componentName = this.checkQuyenHoSoTrucTuyen ? nameComponent : this.checkQuyenHoSoTrucTuyenBoSung ? 'trucTiepBoSung' : ''
    } else {
      // eslint-disable-next-line no-nested-ternary
      this.componentName = this.checkQuyenHoSoTrucTuyen ? 'trucTiep' : this.checkQuyenHoSoTrucTuyenBoSung ? 'trucTiepBoSung' : ''
    }
    const result = this.permissionType.find(item => item.type === this.componentName)
    this.permissionName = result ? result.name : null
    this.getThuTucHanhChinh()
    this.getLinhVuc()
    this.getDataHoSo()
  },
  methods: {
    showFormFilter() {
      this.formFilterShow = !this.formFilterShow
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    getThuTucHanhChinh() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.tthcCbx = res.data.data
        }
      })
    },
    getLinhVuc() {
      this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.lvCbx = res.data.data
        }
      })
    },
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.tthcCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    getDataHoSo() {
      this.isLoading = false
      try {
        if (this.componentName === 'trucTiep') {
          this.payload.trangThai = 0
        } else if (this.componentName === 'trucTiepBoSung') {
          this.payload.trangThai = 5
        }
        const result = this.permissionType.find(item => item.type === this.componentName)
        this.permissionName = result ? result.name : null
        let payload = _cloneDeep(this.payload)
        if (!_isEmpty(this.$route.query)) {
          payload = {
            ...payload,
            thoiGianTiepNhanTu: this.$route.query.tuNgay,
            thoiGianTiepNhanDen: this.$route.query.denNgay,
          }
        }

        if (this.$refs.formFilter) {
          payload = {
            ...payload,
            ...this.$refs.formFilter.getFormFilter(),
          }
        }

        this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.DANH_SACH_CHO_TIEP_NHAN, payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
        if (this.$route.query.name) {
          this.$router.replace({ path: '/tiep-nhan-ho-so/hs-truc-tuyen/tiep-nhan-ho-so-truc-tuyen' })
        }
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    resetFilter() {
      this.payload = {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.columnFilter()
      })
    },
    refreshCommon() {
      this.$refs.vgTable.$refs.vbTables.reset()
      this.resetFilter()
      this.columnFilter()
    },
    clearFilter(field) {
      const found = this.columns.find(c => c.field === field)
      found.filterOptions.filterValue = ''
    },
    rejectHoSo(data) {
      this.title = 'Lý do từ chối tiếp nhận hồ sơ'
      this.componentNameModal = 'LyDoTuChoHoSo'
      this.size = 'lg'
      this.dataForm = {
        title: 'lý do từ chối tiếp nhận hồ sơ',
        lyDoTuChoi: null,
      }
      this.titleOk = 'Đồng ý'
      this.selectedItems = [data]
      this.$refs.commomnModal.showModal()
    },
    detailHoSo(data) {
      let type = null
      if (this.componentName === 'trucTiep') {
        type = 'truc-tiep'
      } else if (this.componentName === 'trucTiepBoSung') {
        type = 'bo-sung'
      }
      this.$router.push({ path: ROUTE_NAME.HS_TRUC_TUYEN.CT, query: { id: data.id, name: this.$route.name, type } })
    },
    async handleOk() {
      if (this.componentNameModal === 'LyDoTuChoHoSo') {
        const payload = {
          dsTuChoiTiepNhanHoSo: this.selectedItems.map(item => ({
            id: item.id,
            lyDoTuChoi: this.dataForm.lyDoTuChoi,
          })),
        }
        this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.TU_CHOI, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.rows.length === 1 && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataHoSo()
            this.$refs.commomnModal.hideModal()
          }
        })
      } else if (this.componentNameModal === 'YeuCauBoSung') {
        const payload = {
          hoSoId: this.hoSoId,
          hoSo_GiayToBoSungModels: this.dataForm.map((item, index) => ({
            ...item,
            thuTu: index,
          })),
        }
        this.$axios.post(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.BO_SUNG}`, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.rows.length === 1 && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataHoSo()
          }
        })
      } else {
        const payload = {
          dsTiepNhanHoSo: this.dataForm.map(item => ({
            id: item.id,
            mauHoSoId: item.mauHoSoId,
            thongTinMota: null,
            ngayNop: new Date(item.ngayNop),
            gioHenTraTu: null,
            gioHenTraDen: null,
            ngayHenTraKq: new Date(item.ngayHenTraKq),
            phi: item.phi,
            lePhi: item.lePhi,
          })),
        }
        this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.TIEP_NHAN, payload).then(ress => {
          if (ress.data?.succeeded) {
            const isAllTrue = ress.data.data.map(item => item.succeeded).every(item => item)
            if (this.selectedItems.length >= this.rows.length && isAllTrue && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataHoSo()
            this.$refs.commomnModal.hideModal()
          }
          if (ress.data?.data) {
            ress.data.data.forEach(element => {
              if (!element.succeeded) {
                this.$toast.error(element.message, { timeout: 3000 })
              }
            })
          }
        })
      }
    },
    async axiosKetQuaTrucTiep(payload) {
      const result = await this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.LAY_HAN_TRA_KET_QUA, payload, false)
      return result
    },
    // Iterates all users and returns their Github info.
    async axiosdsKetQuaTrucTiep(payloadList) {
      const requests = payloadList.map(item => this.axiosKetQuaTrucTiep(item).then(a => a))
      return Promise.all(requests)
    },
    deleteHoso(index) {
      this.dataForm = this.dataForm.filter((item, idx) => idx !== index)
    },
    implementHoSo(data) {
      this.title = 'Yêu cầu bổ sung'
      this.hoSoId = data.id
      this.dataForm = [{
        tenGiayTo: null,
        banChinh: 0,
        banSao: 0,
        banPhoto: 0,
        dsHoSoGiayToFileDinhKem: [],
      }]
      this.titleOk = 'Lưu'
      this.size = 'xl'
      this.componentNameModal = 'YeuCauBoSung'
      this.$refs.commomnModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      let payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      if (this.$refs.formFilter) {
        payload = {
          ...payload,
          ...this.$refs.formFilter.getFormFilter(),
        }
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.DANH_SACH_CHO_TIEP_NHAN, payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        dataTTHCId: this.payload.dataTTHCId,
        dataLinhVucId: this.payload.dataLinhVucId,
        fulltextsearch: null,
        chuHoSo: data?.chuHoSo_HoVaTen ? data?.chuHoSo_HoVaTen.replace(/\s+/g, ' ').trim() : '',
        maHoSo: data?.code ? data?.code.replace(/\s+/g, ' ').trim() : '',
        diaChiChuHoSo: data?.chuHoSo_DiaChiDayDu ? data?.chuHoSo_DiaChiDayDu.replace(/\s+/g, ' ').trim() : null,
        thoiGianTiepNhanTu: null,
        thoiGianTiepNhanDen: null,
        thoiGianHenTraTu: null,
        thoiGianHenTraDen: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.payload.navCode,
        pageNumber: 1,
        pageSize: this.payload.pageSize,
        trangThai: this.payload.trangThai,
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.DANH_SACH_CHO_TIEP_NHAN, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    acceptHoSo() {
      this.title = 'Tiếp nhận nhiều hồ sơ'
      this.componentNameModal = 'TiepNhanNhieuHoSo'
      this.size = 'xl'
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.MAU_HO_SO).then(res => {
        if (res.data?.succeeded) {
          const mhsCbx = res.data.data
          this.dataForm = this.selectedItems.map(item => ({
            id: item.id,
            chuHoSo_HoVaTen: item.chuHoSo_HoVaTen,
            ngayNop: new Date(),
            mauHoSoId: null,
            thuTucHanhChinhId: item.thuTucHanhChinhId,
            tenThuTucHanhChinh: item.tenThuTucHanhChinh,
            mhs: mhsCbx.filter(mhs => mhs.thuTucHanhChinhId === item.thuTucHanhChinhId),
            ngayHenTraKq: null,
            phi: item.phiHs,
            lePhi: item.lePhi,
          }))
          this.titleOk = 'Đồng ý'
          this.$refs.commomnModal.showModal()
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .card .card-header {
    border-bottom: 1.5px solid #EEEEEE !important;
    display: inline-block;
    padding: 0.5rem 1.5rem !important;
  }
  .cs-table {
    min-height: 150px;
    .actions-col-width {
      max-width: 50px;
    }
  }
  .font-weight-bold-700 {
    font-weight: 700;
    color: #333333;
  }
  .text-top{
    margin-top:6px;
  }
</style>
